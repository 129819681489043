import { useQuery } from "@tanstack/react-query";

import { GetRuleSetResponse } from "@revv/data";

import { useStateContext } from "../context";

const baseUrl = "/v1/rule-sets";

export function useRuleSet(id: string) {
  const { apiClient } = useStateContext();

  const { data, error, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["ruleSets", id],
    queryFn: async () => {
      const response = await apiClient.get<GetRuleSetResponse>(
        `${baseUrl}/${id}`
      );

      return response.data.ruleSet;
    },
  });

  return {
    ruleSet: data,
    error,
    isLoading,
  };
}
