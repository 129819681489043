import { useQuery } from "@tanstack/react-query";

import { Country, GetReportResponse } from "@revv/data";

import { useStateContext } from "../context";
import { baseUrl } from "./useReports";

export type ReportDetailsParams = {
  type: "internal" | "customer";
  country?: Country;
};

export function useReport(id: string, params: ReportDetailsParams) {
  const { apiClient } = useStateContext();

  const { data, error, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["reports", id, JSON.stringify(params)],
    queryFn: async () => {
      const queryParams = convertParams(params);

      const response = await apiClient.get<GetReportResponse>(
        `${baseUrl}/${id}/${params.type}`,
        { params: queryParams }
      );

      return response.data;
    },
  });

  return {
    report: data,
    error,
    isLoading,
  };
}

function convertParams(params: ReportDetailsParams) {
  const queryParams = new URLSearchParams();

  if (params.country) {
    queryParams.append("country", params.country);
  }
  return queryParams;
}
