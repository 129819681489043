import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { RevisionResponse, RevisionStatus } from "@revv/data";

import { useStateContext } from "../context";

const baseUrl = "/v1/revisions";

export function useRevision(id: string) {
  const { apiClient } = useStateContext();
  const queryClient = useQueryClient();

  const queryKey = ["revision", id];

  const { data, error, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey,
    queryFn: async () => {
      const response = await apiClient.get<RevisionResponse>(
        `${baseUrl}/${id}`
      );

      return response.data;
    },
  });

  const reviewMutation = useMutation({
    mutationFn: async (
      action: RevisionStatus.APPROVED | RevisionStatus.REJECTED
    ) => {
      const res = await apiClient.post<RevisionResponse>(`${baseUrl}/review`, {
        revisionId: id,
        action,
      });

      return res.data;
    },
    onSuccess: (data) => {
      if (!data?.revision) {
        return;
      }

      queryClient.setQueryData(queryKey, data);
    },
  });

  const restoreMutation = useMutation({
    mutationFn: async () => {
      const res = await apiClient.post<RevisionResponse>(`${baseUrl}/restore`, {
        revisionId: id,
      });

      return res.data;
    },
    onSuccess: (data) => {
      if (!data?.revision) {
        return;
      }

      queryClient.setQueryData(queryKey, data);
    },
  });

  return {
    revision: data?.revision,
    error,
    isLoading,
    reviewMutation: {
      mutateAsync: reviewMutation.mutateAsync,
      isPending: reviewMutation.isPending,
      error: reviewMutation?.error || undefined,
    },
    restoreMutation: {
      mutateAsync: restoreMutation.mutateAsync,
      isPending: restoreMutation.isPending,
      error: restoreMutation?.error || undefined,
    },
  };
}
