import {
  ServiceCalibrationType,
  ServiceOperationType,
  ServiceType,
} from "./services";

export enum RateSheetType {
  FLAT = "flat",
  LABOR = "labor",
}

export type RateSheetService = {
  id: number;
  autoMakeId?: number;
  insuranceId?: number;
  type: RateSheetType;
  accountId: number;
  shopId: number;
  name: string;
  serviceType: ServiceType;
  operationType: ServiceOperationType;
  calibrationType: ServiceCalibrationType;
  autopopulate: boolean;
  createdBy: number;
  updatedBy: number;
  createdAt: string;
  updatedAt: string;
  makes: string[];
  description?: string;
  price?: number;
  defaultLinkUrl?: string;
  defaultLinkText?: string;
  legacyId?: number;
  rateSheetId: number;
  laborHours?: number;
};

export type RateSheetServiceCreateData = {
  name: RateSheetService["name"];
  description: RateSheetService["description"];
  price?: RateSheetService["price"];
  laborHours?: RateSheetService["laborHours"];
  defaultLinkUrl?: RateSheetService["defaultLinkUrl"];
  defaultLinkText?: RateSheetService["defaultLinkText"];
  makes: RateSheetService["makes"];
  rateSheetId: RateSheetService["rateSheetId"];
  serviceType: RateSheetService["serviceType"];
  operationType: RateSheetService["operationType"];
  type: RateSheetService["type"];
  calibrationType: RateSheetService["calibrationType"];
  autopopulate: RateSheetService["autopopulate"];
};

export type UpdateRateSheetServiceResponse = {
  service: RateSheetService;
};

export type RateSheetResponseType = {
  rateSheetServices: RateSheetService[];
  adasHasMore: boolean;
  functionalHasMore: boolean;
  safetyHasMore: boolean;
  steeringHasMore: boolean;
};

export type RateSheetServicesResponse = {
  [ServiceType.DEFAULT]: RateSheetResponseType;
  [ServiceType.CUSTOM]: RateSheetResponseType;
};

export type RateSheetServicesQueryData = {
  [operationType in ServiceOperationType]: {
    services: RateSheetService[];
    hasMore: boolean;
  };
};
